body {
  font-family: 'MuseoSans', sans-serif;
}


#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #52a5c9;
  /* border-bottom: 4px solid red; */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Add animation to "page content" */
#loader-outer {
  z-index: 999;
  background: #ffffff;
  width: 100%;
  height: 100vh;
  display: block;
  position: fixed;
}

/* Loader For Tabs */

#loader-outerForTab {
  z-index: 999;
  background: #ffffff;
  width: 100%;
  height: 100vh;
  display: flex ;
  justify-content: center;
  align-items: center;
  position: relative;
}
#loaderForTab {
  /* position: absolute;
  left: 50%;
  top: 50%; */
  z-index: 1;
  width: 120px;
  height: 120px;
  /* margin: -76px 0 0 -76px; */
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #52a5c9;
  /* border-bottom: 4px solid red; */
  animation: spin1 2s linear infinite;
}

@keyframes spin1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sidebar-conatiner {
  font-size: 20px;
}

.grid-contain {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  /* Fixed 290px width for each column */
  grid-auto-rows: minmax(30px, auto);
  /* Adjust minimum height for heading row as needed */
  gap: 5px;
  /* Optional spacing between columns */
  /* overflow-x: auto; */
}

/* .grid-contain > div {
    width: 100%;
  } */
.grid-contain1 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(6.4%, 1fr));
  /* 6 equal-width columns */
  gap: 1rem;
  /* Adjust spacing between items */
  word-wrap: break-word;
}

.grid-contain1>div {
  width: 100%;
}

.grid-contain2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1rem;
  /* Adjust spacing between items */
  word-wrap: break-word;
}

.col {
  width: 280px;
}

.shadowEffect {
  box-shadow: 2px 2px solid black;
}

.option {
  border: none;
}

/* h1 {
    background: linear-gradient(45deg, #1a1a22, #2d665c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  } */

.customHeading {
  background-image: linear-gradient(to right, #915fca, #420f8685);
  -webkit-background-clip: text;
  color: transparent;
}


.dataentry {
  border: 1px solid gray;
  /* padding: 3px; */
  /* padding-right: 3px; */
  margin-left: auto;
  margin-right: auto;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;

}

button:disabled:hover,
button[disabled]:hover {
  /* Apply the same styles as for the disabled state */
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.arrow {
  font-weight: 100;
  column-gap: 0;
}

.filterbtn {
  background-color: #4fd0ff;
  color: rgb(32, 32, 32);
  cursor: pointer;
  font-size: 0.9em;
  padding: 1% 2%;
}

.filter-section-container {
  position: fixed;
  background-color: rgba(82, 150, 182, 0.84);
  width: 60vw;
  min-height: 40vh;
  height: fit-content;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  border: 1px solid #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.filter-section-content {
  padding: 20px;
  margin-bottom: 60px;
}

.filter-section-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.filter-section-input,
.filter-section-select {
  padding: 10px;
  font-size: 0.9em;
  height: 7vh;
  width: 13vw;
  /* background-color: white; */
}

.disabled-icon {
  opacity: 0.5;
  /* Reduce opacity to indicate disabled state */
  cursor: not-allowed;
  /* Change cursor to indicate disabled state */
}

/* Define a CSS class for disabled buttons */
.disabled-button {
  pointer-events: none;
  /* Disable pointer events to prevent interaction */
}

.disabledDropdown {
  color: yellow !important;
}

.filter-section-buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  margin-top: 50px;
}

.add-more-filters-button {
  padding: 10px;
  background-color: #4fd0ff;
  color: rgb(32, 32, 32);
  border: none;
  /* border-radius: 5px; */
  cursor: pointer;
}

.apply-button {
  padding: 10px;
  background-color: #4fd0ff;
  color: rgb(32, 32, 32);
  border: none;
  /* border-radius: 5px; */
  cursor: pointer;
}

/* .col{
  width: 380px;
} */
.showhide-section-container {
  position: fixed;
  background-color: rgba(82, 150, 182, 0.84);
  width: 40vw;
  min-height: 40vh;
  height: fit-content;
  top: 20%;
  z-index: 50;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  border: 1px solid #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.table-container {
  /* width: calc(100% - 20%); */
  /* Subtracting the left margin from 100% */
  overflow-x: auto;
  /* white-space: nowrap;  */
  /* Prevent wrapping of table cells */
}

.table-container {
  display: flex;
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
  position: relative;
}


.filter-section-input:focus {
  outline: none !important;
}

.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show-more {
  cursor: pointer;
  /* Add custom styles for hover/click effects, etc. */
}


.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  /* Ensure the modal is on top of everything */
}

.modal textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  resize: none;
}

.modal button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #0056b3;
}

.icon-shine {
  animation: shine-animation 1.5s infinite;
}

@keyframes shine-animation {
  0% {
    filter: brightness(100%);
  }

  50% {
    filter: brightness(150%);
  }

  100% {
    filter: brightness(100%);
  }
}

.Toastify__toast-container {
  width: fit-content !important;
  font-size: 0.8em;
  /* Set the desired width */
}

.nav-item:after {
  position: absolute;
  left: 0px;
  content: '';
  height: 1px;
  width: 0px;
  border-bottom: none;
  transition: all 1000ms ease-in-out;
  opacity: 1;
}

.nav-item {
  position: relative;
  /* Position parent li */
}

.nav-item ul {
  display: none;
  /* Hide ul by default */
  position: absolute;
  /* Position ul absolutely */
  top: 100%;
  /* Align ul below parent li */
  left: 0;
  /* Align ul with parent li */
  background-color: #1a1a22;
  /* Background color of ul */
  border: 1px solid #afaeae;
  /* Border of ul */
  padding: 0;
  /* Remove default padding */
  margin: 0;
  /* Remove default margin */
  color: white;
  width: 100%;
  opacity: 0;
  /* Initially hide with 0 opacity */
  transform: translateY(-10px);
  /* Initial position */
  transition: opacity 0.3s ease, transform 1s ease 1s;
}

.nav-item:hover ul {
  display: block;
  opacity: 1;
  /* Show with full opacity */
  transform: translateY(0);
  /* Move to the original position */
}

.nav-item ul li {
  list-style: none;
  /* Remove default list style */
}

.nav-item ul li {
  display: block;
  /* Display links as block elements */
  padding: 8px 16px;
  /* Add padding to links */
  text-decoration: none;
  /* Remove underline from links */
  color: white;
  /* Link color */
}

.nav-item ul li a:hover {
  /* background-color: #1a1a22; */

  color: rgb(59, 146, 196);
  width: 100%;
  /* Background color on hover */
}

.react-tabs__tab {
  /* Your styles for tabs */
  color: rgb(63, 59, 59);
}



.sidebar-container {
  position: relative;
  width: 100%;
  height: 100%;
}


.nav-item {
  position: relative;
}

.sub-menu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #2d2d2d;
  width: 200px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.nav-item:hover .sub-menu {
  display: block;
}

.nav-item:hover~.nav-item {
  /* transform: translateY(25px); */
  transition: transform 0.3s ease;
}

.webFirstPage {
  background-image: url(../../../public/images/banner-5196281_1280.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Define a darker color for the label when the input is disabled */
input:disabled+.dark-label {
  color: red !important;
  /* Adjust the color to your preference */
}

.partitionLine::after {
  background-color: #915fca;
}

.custom-hr {
  background-color: red !important;
  height: 2px !important;
  border: none;
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.glowing-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  /* This is equivalent to gap-x-1 in Tailwind */
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 0 15px rgba(79, 208, 255, 0.5);
  /* Glowing effect with your color */
  transition: transform 0.3s;
  animation: glowOuter 1.5s infinite alternate;
}

.glowing-div:hover {
  transform: scale(1.05);
  /* Slight zoom on hover */
}

@keyframes glowOuter {
  0% {
    box-shadow: 0 0 5px rgba(5, 40, 53, 0.3);
    /* Start with lighter glow */
  }

  100% {
    box-shadow: 0 0 20px rgba(19, 37, 44, 0.8);
    /* End with a stronger glow */
  }
}

.glowing-div1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem; /* This is equivalent to gap-x-1 in Tailwind */
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  box-shadow: inset 0 0 15px rgba(79, 208, 255, 0.5);
  /* Inner glowing effect */
  transition: transform 0.3s;
  animation: glowInner 1.5s infinite alternate;
  /* background-color: lightblue; */
}

@keyframes glowInner {
  0% {
    box-shadow: inset 0 0 5px rgba(121, 203, 233, 0.3); /* Start with a lighter inner glow */
    /* background-color: rgb(171, 215, 241); */
  }

  100% {
    box-shadow: inset 0 0 20px rgba(124, 175, 196, 0.8); /* End with a stronger inner glow */
    /* background-color: lightblue; */
  }
}
.btn4 {
  background-color: #0a1d47;
  color: white;
  padding: 3px 10px; /* Adjust padding for better spacing */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  cursor: pointer;
  border: 1px solid #27278b;
  border-radius: 3px;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.btn4:hover {
  background-color: white;
  color: #27278b;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4); /* Slightly darker shadow on hover */
}


.newuserSignup {
  background-image: url('/public/images/cyber-security-2296269_1920.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.newuserSignup>div {
  box-shadow: 0 0 8px #4fd0ff;
}