.navbarRespons {
  background-color: #0d1321;
}
/* .navbarRespons.scrolled{
  
  } */
.navbar-brand img {
  /* width: 180px; */
  margin-top: -15px;
  transition: all 0.3s;
}
.navbar-brand > img {
  display: block;
}
img {
  vertical-align: middle;
}
@media (max-width: 1024px) {
  .navbar-brand > img {
    display: block;
    /* width: 150px; */
  }
}
@media (max-width: 479px) {
  .navbar-brand > img {
    width: 40%;
    margin-top: 0px;
    display: inline-block;
  }
}
.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}
.header1 {
  width: 100%;
  float: left;
  padding: 20px 0px 20px 0px;
  background-color: #0d1321;
  height: 100px;
}
/* nav{
   
  } */
.navbar {
  position: sticky;
  width: 100%;
  z-index: 40;
  background-color: #0d1321;
  transition: 500ms ease;
  color: white;
  /* transition: padding 500ms ease; */
}

.navbar.compressed {
  transition: 1000ms ease-in;
  width: 150px;
  transition: all 0.3s;
  /* margin-top: -15px; */
  padding: 15px 0;
}
.expanded {
  transition: 500ms ease;
  padding: 20px 0px;
  /* transition: all 500ms; */
}
.expanded img {
  width: 180px;
  object-fit: contain;
}
.collapsed img {
  width: 150px;
  object-fit: contain;
}
@media (max-width: 1024px) {
  .collapsed img {
    width: 150px;
  }
}
@media (max-width: 1024px) {
  .expanded img {
    width: 150px;
  }
}
.collapsed {
  padding: 15px 0px;
}
@media (max-width: 479px) {
  .navbar {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 0;
  }
  .navbar.collapsed {
    padding: 10px 0;
  }
  .navbar.collapsed img {
    width: 166px;
  }
  .navbar.expanded img {
    width: 190px;
    object-fit: contain;
  }
}
@media (max-width: 350px) {
  .navbar {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 0;
  }
  .navbar.collapsed {
    padding: 10px 0;
  }
  .navbar.collapsed img {
    width: 130px;
  }
  .navbar.expanded img {
    width: 100px;
    object-fit: contain;
  }
}

.navbarRight{
 /* max-width: 740px; */
 max-width: 940px;
  /* column-gap:45px; */
  column-gap: 20px;
}
.navbarRight li{
 font-size: 13px;
}
.navbar-nav > li > .dropdown-menu {
  margin-top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.navbar-nav > li {
  float: left;
  /* width: 125px; */
}
.dropdown-menu {
  background-color: #0d1321;
  left: 0 !important;
  width: 140px;
  padding: 0px 0;
  min-width: auto;
}
.dropdown:hover .dropdown-menu {
  display: block;
  width: 140px;
  /* height: 10px; */
  /* padding-bottom: 5px; */
}
.dropdown:hover:after{
  width: 140px;
  /* height: 10px; */
}
.dropdown-menu > li > a {
  display: block;
  padding: 10px 10px;
  font-size: 12px;
  clear: both;
  font-weight: 400;
  line-height: 16px;
  color: #ffffff;
  background-color: #0d1321;
  white-space: nowrap;
  border-bottom: 1px solid #4c5151;
  font-family: "Ubuntu", sans-serif;
  /* margin-top: 15px; */
 
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #000;
  text-decoration: none;
  background-color: #63c7f2;
  padding: 10px 10px;
}
.dropdown {
  position: relative;
  
}

/* .navbar-dropdown-child{
      
        } */
.hoverUsp:hover {
  background-color: white;
  border: 1px solid #4fd0ff;
  color: black;
  border-radius: 12px;
  /* transform: rotate(2); */
}
.navIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
 
}
.navIcon .angle {
  padding-left: 3px;
  font-size: 13px;
  font-weight: 10;
  color: gray;
}
.menuIcon {
  /* font-size: 25px; */
  font-weight: 400;
  font-size: 50px;
  color: #64c7f1;
  stroke-width: 3%;
  object-fit: fill;
  aspect-ratio: 3 / 2;
}
/* .navbar-collapse {max-height: 100%;}
      .navbar-default .navbar-collapse, .navbar-default .navbar-form {border-color: #0d1321;background-color: #0d1321;}
      @media (max-width: 1024px) {
        .navbar-collapse {border-top: 1px solid transparent; box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);}
        .navbar-collapse.collapse {display: none!important;}
        .navbar-default .collapse {float: none;}
        .navbar-nav {float: none!important; margin: 0 !important;}
        .navbar-nav>li {float: none;}
        .navbar-nav>li>a {padding-top: 10px; padding-bottom: 10px;} 
      .navbar-text {float: none; margin: 15px 0;} 
        
        .navbar-collapse.collapse.in {display: block!important; background-color: #0d1321;}
      } */
.navbar-small {
  height: auto;
  background-color: #000;
  position: absolute;
  width:100%;
  left: 0%;
  margin: auto;
  top: 100%;
  /* bottom: 0%; */
}
@media(min-width:765px){
.navbar-small{
  width: 760px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
}
.navbar-small ul {
  border-top: 1px solid rgb(250, 249, 249);
  height: auto;
  width: 100%;
  background-color: #0d1321;
}
.navbar-small li {
  padding-top: 5px;
  /* padding-bottom: 5px; */
}
/* @media(max-width:479px){

  .navbar-small li a{
    text-align: center;
  }
  .dropdown-small a{
    text-align: center;
  }
  .navbar-small ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .navbar-menuSmall ul{
position: absolute;
left: -50%;
  }
} */

.nav-item {
  margin-bottom: 10px;
}

.main-icon {
  cursor: pointer;
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
}

.sub-icons {
  display: none;
  flex-direction: column;
}

.sub-icon {
  background-color: #555;
  color: white;
  padding: 8px;
  text-align: center;
  cursor: pointer;
}

.open .sub-icons {
  display: flex;
}

/* for smaller viewport */

.dropdown-menuSmall {
  background-color: #0d1321;
  left: 0 ;
  width: 100%;
  padding: 0px 0;
}
@media(max-widt:479px){
  .dropdownSmall:hover  .dropdown-menuSmall{
    position: absolute;
    left: 10px;
  }
}
.dropdownSmall:hover .dropdown-menuSmall {
  display: block;
  width: 100vw;
  height: auto;
}
@media(min-width:765px){
  .dropdownSmall:hover .dropdown-menuSmall {
    width: 76vw;
  }
}
.dropdown-menuSmall > li > a {
  display: block;
  padding: 10px 10px;
  font-size: 12px;
  clear: both;
  font-weight: 400;
  line-height: 16px;
  color: #ffffff;
  background-color: #0d1321;
  white-space: nowrap;
  border-bottom: 1px solid #4c5151;
  font-family: "Ubuntu", sans-serif;

}
.dropdown-menuSmall > li > a:focus,
.dropdown-menuSmall > li > a:hover {
  color: #000;
  text-decoration: none;
  background-color: #63c7f2;
  /* padding: 10px 10px; */
}
.dropdownSmall {
  position: relative;
}
.dropdownSmall:after {
  position: absolute;
  left: 0px;
  content: "";
  height: 1px;
  width: 0px;
  border-bottom: solid 1px #63c7f2;
  transition: all 500ms ease-in-out;
  opacity: 1;
 
}
.dropdown:after {
  position: absolute;
  left: 0px;
  
  content: "";
  height: 1px;
  width: 0px;
  border-bottom: solid 1px #63c7f2;
  transition: all 500ms ease-in-out;
  opacity: 1;
}