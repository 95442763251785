.licenseDetail{
font-size: 18px !important;
}

@media screen and (max-width: 1080px) {
    .licenseDetail {
        font-size: 16px !important;
    } 
}
/* Media query for screens with a minimum width of 1380px */
@media screen and (min-width: 1380px) {
    .licenseDetail {
        font-size: 21px !important ;
    } 
}
/* @media screen and (min-width: 1380px) {
.licenseCreation{
font-size: 20px;
    }} */
     