.sidebar-container {
    position: relative;
}

.nav-item ul {
    display: none;
    position: relative;
    background-color: #2C3850;
}

.nav-item:hover ul,
.nav-item.active ul {
    display: block;
}

.nav-item ul li {
    /* padding-left: 2rem; */
}

.nav-item ul li a {
    display: block;
    /* padding:  1rem; */
}

.nav-item ul li a:hover {
    /* background-color: #3E4A6B; */
    /* color: white; */
}

.nav-item ul {
    /* margin-top: 1rem; */
     /* Add margin to prevent overlap */
}

.hover-bg-blue-300:hover {
    /* background-color: #63b3ed; */
}
.collapsed-view {
    position: fixed; /* Keeps the sidebar in place */
    z-index: 50; /* Ensure it overlays the parent content */
}

.parent-content {
    z-index: 20; /* Lower than the sidebar's z-index */
    position: relative; /* Keep the content within its own stacking context */
}
.child-element {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 50; /* Ensure this is higher than the parent's z-index */
}
