@tailwind base;
@tailwind components;
@tailwind utilities;

/* **** Custom Font Usage
  .font-medium ----> used in Dlp Page (dlpProduct)
  .font-low -----> used in IR Page (Explain)
**** */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'MICROGME', 'Ubuntu', sans-serif;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* Uncomment if you want to hide overflow */
  /* overflow-x: hidden; */
  /* overflow-y: hidden; */
}


.btn {
  display: inline-block;
  margin: 2px 0 0 3px;
  padding: 7px 15px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0px;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  box-shadow: 0 0 7px #4D9BBE
}

.btn-default {
  /* color: #63C7F2; */
  color: white;
  border: 1px solid black;
  border-color: #0D1523;
  background-color: transparent;
  /* background-color:#0D1523; */
}

.btn-default:hover {
  color: #0D1523;
  background-color: #63C7F2;
  border-color: #4D9BBE;
}

.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default {
  color: #0D1523;
  background-color: #64c7f1;
  border-color: #64c7f1;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
  color: #0D1523;
  background-color: #64c7f1;
  border-color: #0D1523;
}

.btn-default.focus,
.btn-default:focus {
  color: #0D1523;
  background-color: #64c7f1;
  border-color: #64c7f1;
}
.dlpHomeSlider {
  background-image: url('.././public/images/DLP.jpeg');
  /* background-image: url('.././public/images/security-background-3497068_1280.webp'); */
  background-size: cover;
  /* float: right; */
  position: float;
  background-position: 40% 50%;
  background-repeat: no-repeat;
  margin-top: 0px;
  color: black;
  position: relative;
  z-index: 2;
  /* opacity: 0.5; */
  /* z-index: 10; */
}

@media (max-width:640px) {
  /* .dlpHomeSlider::after{
    content: ''; 
    position: absolute;
    top: 0;
    left: 0;
     z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(17, 17, 20, 0.4);
  } */
}
.dlpHomeSlider p {
  /* text-align: center; */
  /* max-width:56%; */
  /* padding-top: 10%;
  padding-bottom: 2%; */
  /* position: relative; */
  /* width: 100%; */
}
.dlpHomeSlider div {
  position: relative;
}

.irHomeSlider {
  background-image: url('.././public/images/background-3883181.jpg');
  position: float;
  background-size: cover;
  /* Adjusts the size of the background image */
  background-position: right;
  float: right;
  width: 80%;
  position: relative;
}
.heroDlp {

  /* background-image: url('../../public/images/cybersecurity-5642004_1920.jpg'); */
  background-size: cover;
  /* float: right; */
  position: float;
  background-position: center;
  background-repeat: no-repeat;
  color: black;
  /* opacity: 0.5; */
  /* z-index: 10; */
}
@media(min-width:1400px) {
  .dlpHomeSlider p {}

}

.irHomeSlider {

background-image: url('.././public/images/background-ir-3883181.jpg');
  /* background-image: url('.././public/images/background-3883181.jpg'); */
  background-size: cover;

  position: float;
  background-position: right; 
  background-repeat: no-repeat;
  color: aqua;
}
.irHomeSlider>div {
  width: 90%;
}
.edrHomeSlider {
  background-image: url('.././public/images/EDR.png');
  background-size: cover;
  /* float: right; */
  position: float;
  background-position: 30% 50%;
  background-repeat: no-repeat;
  color: aqua;

  /* opacity: 0.5; */
  /* z-index: 10; */

}

.edrHomeSlider>div {
  width: 90%;
}

.edrHomeSlider p {

  /* font-size:37px;
 line-height: 42px;
 font-weight: 400;
 letter-spacing: 3px;
 margin-bottom: 10px; */
}

@media (min-screen:1400px) {
  .edrHomeSlider p {
    font-size: 38px;
    line-height: 46px;
  }
}

@media (min-width:640px) {
  .edrHomeSlider p {
    /* font-size: 26px;
     margin-bottom: 7px;
     line-height: 35px; */
  }
}
@media (max-width:640px) {
  .edrHomeSlider p {
    /* font-size: 20px;
    line-height: 26px;
    margin-bottom: 5px; */
  }
}

.App-link {
  color: white;
}

.navbar-link {
  color: white
}

.navbar-link:active {
  color: rgb(79, 208, 255);
}

.navbar-link:hover {
  color: rgb(79, 208, 255);

}
.hero-background {

  position: float;
  background-size: cover;
  /* Adjusts the size of the background image */
  background-position: right;
  float: right;
  /* color: #4fd0ff; */
  position: relative;
}

.hero-background::after {
  content: '';
  /* Required for pseudo-elements */

  /* background-color: rgba(79, 208, 255, 0.1); */
  /* Overlay color (blue with 50% opacity) */
}

.hero-background>div>div>p {
  position: absolute;
  top: 70%;
}

.hoverUsp:hover {
  background-color: white;
  border: 1px solid #4fd0ff;
  color: black;
  border-radius: 12px;
  /* transform: rotate(2); */
}

.dlpHeroBtn {
  border: 1px solid #3a6077 !important;
  padding: 3px 5px;
  background-color: #0d1321;
  color: white;

}

.partner-heading {
  /* font-size: 50px; */
  background: -webkit-linear-gradient(#1a1a22, #4fd0ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.award-line {
  position: relative;
}

.award-line::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 35%;
  left: 32%;
  height: 4px;
  border-radius: 2px;
  background: linear-gradient(90deg, #4fd0ff 0%, #1a1a22 100%);

}

@media (max-width: 650px) {
  .award-line::before {
    display: none;
  }
}

.matisoft h1 {
  width: 100%;
  position: relative;
}

.matisoft h1:before,
.matisoft h1:after {
  content: "";
  position: absolute;
  top: 50%;
  border-bottom: 2px solid black;
  width: 26%;
}

.matisoft h1:before {
  right: 5%;
}

.matisoft h1:after {
  left: 5%;

}

@media screen and (min-width: 650px) and (max-width: 1280px) {

  .matisoft h1:before,
  .matisoft h1:after {
    content: "";
    position: absolute;
    top: 50%;
    border-bottom: 2px solid black;
    width: 16%;
  }
}

@media (max-width:650px) {

  .matisoft h1:before,
  .matisoft h1:after {
    display: none;
  }
}

.useCase-underline {
  width: 100%;
  position: relative;
}

@media (min-width:1280px) {
  .useCase-underline:before,

  .useCase-underline:after {
    content: "";
    position: absolute;
    top: 50%;
    border-bottom: 2px solid black;
    width: 29%;
  }

  .useCase-underline:before {
    right: 5%;
  }

  .useCase-underline:after {

    left: 5%;

  }
}

@media screen and (min-width:650px) and (max-width:1280px) {

  .useCase-underline:before,
  .useCase-underline:after {
    content: "";
    position: absolute;
    top: 50%;
    border-bottom: 2px solid black;
    width: 18%;
  }

  .useCase-underline:before {
    right: 5%;

  }

  .useCase-underline:after {
    left: 5%;

  }
}

.award-underline {
  width: 100%;
  position: relative;
}

@media(min-width:1280px) {
  .award-underline:before,
  .award-underline:after {
    content: "";
    position: absolute;
    top: 50%;
    border-bottom: 2px solid #4fd0ff;
    width: 29%;
  }

}

.award-underline:before {
  right: 5%;

}

.award-underline:after {
  left: 5%;

}

@media screen and (min-width:650px) and (max-width:1280px) {

  .award-underline:before,
  .award-underline:after {
    content: "";
    position: absolute;
    top: 50%;
    border-bottom: 2px solid #4fd0ff;
    width: 20%;
  }
}

.partner-sideline {
  width: 100%;
  position: relative;
}

.partner-sideline:before,
.partner-sideline:after {
  content: "";
  position: absolute;
  top: 50%;
  border-bottom: 2px solid black;
  width: 28%;
}

.partner-sideline:before {
  right: -21%;

}

.partner-sideline:after {
  left: -21%;

}

.partner-Heading {
  /* width: 100%; */
  position: relative;
}

.partline-Heading h1 {
  width: 100%;
}

.partner-Heading h1:before,
.partner-Heading h1:after {
  content: "";
  position: absolute;
  top: 50%;
  border-bottom: 2px solid black;
  width: 10%;
}

.partner-Heading h1:before {
  right: 5%;

}

.partline-Heading h1:after {
  /* right: 0%; */
  left: 5%;

}

@media (max-width: 650px) {
  .useCase-underline h1::after,
  .award-underline h1::before,
  .award-underline h1:after .matisoft>h1::after,
  .matisoft>h1::before {
    left: 0%;
    right: 0%;
    width: 0%;
    display: none;
  }
}

.curve {
  background-image: linear-gradient(135deg, #1a1a22, #4fd0ff);
}

.curve::after {
  content: '';
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50% 100%;
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background-color: white;
  height: 15%;
}

.shadow {
  color: #1a1a22;
  text-shadow: 2px 2px 4px #4fd0ff;
}

.hoverNavLink {
  display: none;

}

.hoverNavLink:hover {
  display: block;
  position: absolute;
  top: 20px;
  background-color: #9c27b0;
}

.dropdown:hover .dropdown-menu {
  display: block;
  /* left: ; */
}

.customer-name {
  font-size: 24px;
  /* text-transform: uppercase; */
  font-family: 'Ubuntu', sans-serif;
  background: -webkit-linear-gradient(#1a1a22, #1a1a22, #4fd0ff);
  -webkit-background-clip: text;
  background-color: #4fd0ff;
  font-weight: 500;
  -webkit-text-fill-color: transparent;
}

.fontUsed {
  font-family: 'Ubuntu', sans-serif;

}

.font-high {
  margin-top: 0px;
  /* letter-spacing: 8px; */
  /* margin-bottom: 0px; */

  width: 60%;
  font-weight: 550;
  /* text-shadow: 0px 3px 6px #1a1a22; */
  font-size: 32px;
  text-transform: uppercase;
  font-family: 'microgme', sans-serif;
  /* color: white; */
  margin-top: 0px;
  letter-spacing: 8px;
  margin-bottom: 0px;
  line-height: 45px;

  /* font-weight: bold; */
}

@media (max-width: 1024px) {
  .font-high {
    font-size: 26px;
    letter-spacing: 4px;
    margin-bottom: 10px;
    line-height: 36px;
    width: 100%;
  }
}

@media (max-width: 479px) {
  .font-high {
    font-size: 18px;
    letter-spacing: 4px;
    margin-bottom: 0px;
    line-height: 24px;
    width: 100%;
    text-align: left;
  }
}

.font-aboveMed {
  font-family: 'MICROGME', sans-serif;
  /* text-align: center; */
  /* margin: auto; */
  /* text-transform: uppercase; */

  /* color: #4fd0ff; */
  letter-spacing: 4px;
  /* font-weight:500; */
  margin-bottom: 10px;

  margin-top: 0px;
  /* letter-spacing: 8px; */
  /* margin-bottom: 0px; */
  line-height: 0px;
  width: 100%;
  font-weight: 600;
  /* text-shadow: 0px 3px 6px #1a1a22; */
  font-size: 25px;

  /* color: white; */
  margin-top: 0px;
  /* letter-spacing: 8px; */
  /* margin-bottom: 0px; */
  line-height: 30px;
  /* width: 60%; */
  /* font-weight: bold; */
}

.font-medium {

  font-family: 'MICROGME', sans-serif;
  letter-spacing: 4px;
  margin-bottom: 30px;
  line-height: 28px;
  margin-top: 0px;
  font-size: 20px;
  /* text-align: justify; */
}

@media (max-width: 1024px) {
  .font-medium {
    font-size: 20px;
    margin-bottom: 18px;
    line-height: 27px;
  }
}

@media (max-width: 479px) {
  .font-medium {
    font-size: 15px;
    line-height: 22px;
    margin-top: 0px;
    letter-spacing: 3px;
    /* text-align: left; */
  }
}

@media (max-width: 320px) {
  .font-medium {
    font-size: 13px;
  }
}

.font-low {
  list-style-type: none;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  /* color: #fff; */
  margin-bottom: 10px;
}

@media (max-width: 479px) {
  .font-low {
    list-style-type: none;
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    text-align: justify;
  }
}

.font-detail {
  font-family: 'Ubuntu', sans-serif;
  /* margin-bottom: 50px; */
  /* transition: height 1s;s */
  -webkit-transition: height 1s;
  text-align: justify;
  /* font-size: 16px; */
}

.font-subheading {
  /* coolor: #76CEFA; */
  /* text-transform: uppercase; */
  font-family: 'MICROGME', sans-serif;

  font-weight: lighter;
  /* text-shadow: 0px 3px 6px #1a1a22; */
  font-weight: normal;

  /* color: #C9EDFA; */
  letter-spacing: 4px;
  line-height: 25px;
  font-size: 18px;
  /* text-shadow: 0px 3px 6px #44ADE2; */
  margin-top: 10px;
  /* text-align: left; */
  /* margin-left: 7px; */
  margin-bottom: 10px;
  /* padding-left: 2px; */

}

@media(max-width:450px) {
  .font-subheading {
    font-size: 13px;
    line-height: 18px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.font-subheading1 {
  font-weight: normal;
  /* text-transform: uppercase; */
  font-family: 'MICROGME', sans-serif;
  /* color: #C9EDFA; */
  letter-spacing: 3px;
  line-height: 24px;
  font-size: 15px;
  /* text-shadow: 0px 3px 6px #44ADE2; */
  margin-top: 20px;
  /* text-align: left; */
  /* margin-left: 7px; */
  margin-bottom: 10px;
  /* padding-left: 2px; */
}

.font-shadow {
  text-shadow: 0px 3px 6px #1a1a22;
}

.flex-horizontal {
  display: flex;
  text-align: justify;
  align-items: center;
  flex-direction: row;

}

.box {

  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  /* Shadow on the right side */
  position: relative;
  border: 1px solid #4fd0ff;
 
  background-image: linear-gradient(to bottom right, black, #4fd0ff);
  color: #fff;
  transition: all 0.3s ease;
  min-height: fit-content;
  max-height: 29vh;
}

.box::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7px;
  /*   Adjust the height of the border */
  background: linear-gradient(to right, #C9EDFA, transparent);
  /* background-image: linear-gradient(to bottom right,black, #4fd0ff); */
  /* font-size: larger; */
  color: #fff;

}

.box {
  min-height: 26vh;
  width: 100%;
  height: 100%;
}

@media(max-width:1024px) {
  .box {
    min-height: 15vh;
  }
}

.box p {
  width: 90%;
  height: 90%;
  font-size: medium;
  letter-spacing: 1px;
}

.box>p {
  color: #fff;
  transform: scale(1.05);
  /* font-size: 14px; */
  /* font-weight: 540; */
}

@media (min-width:1400px) {
  .box>p {
    font-size: large;
  }
}

.box:hover {
  /* transform: scale(1.03); */
  /* background-image: linear-gradient(to bottom right,black, #4fd0ff); */
  /* font-size: larger; */
  background-image: linear-gradient(to bottom right, #aae6fc, white);
  color: black;

}

.box:hover p {
  color: black;
}


.featureBox {
  background-color: #fff;
  /* Set background color */
  /* box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);  */
  border: 1px solid #4fd0ff;
  /* Shadow on the right side */
  position: relative;
  height: 40vh;

  border-radius: 10px;
  width: 90%;

  /* padding: 0px 8px; */
  height: 45vh;
  margin: auto;
}

.featureBox img {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.featureBox h1 {
  font-size: 22px;
  color: #4cc9f7;
  position: absolute;
  top: 40%;
  left: 5%;
  right: 5%;
  /* transform: translateX(-50%); */
}

.featureBox p {
  position: absolute;
  top: 65%;
  font-size: 13px;
  /* left: 50%; */
  /* background-color: #F7C749; */
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width:1400px) {
  .featureBox p {
    font-size: 15px;
  }
}

.featureHeading {
  position: relative;
  /* display: inline-block; */
  text-align: center;

}

.featureHeading h1 {
  width: 100%;
  /* text-align: center; */
  margin-left: auto;
  margin-right: auto;
  /* text-shadow: 0px 3px 5px #1a1a22; */
}

.firewall {

  /* background-image:url('C:\Users\Prachi\Desktop\MatiSoft\matisoftlabs - Copy\public\images\aug_17_01-ai.jpg'); */
  /* position: float; */
  /* object-fit: contain; */
  background-size: fill;
  /* Adjusts the size of the background image */
  background-position: center;
  /* float: right;s */
  background: transparent linear-gradient(113deg, #1F7073 0%, #083851 24%, #1E102F 63%, #1A0F30 82%, #30213D 100%) 0% 0% no-repeat padding-box;
  /* background-image: linear-gradient(to right,black, #4fd0ff); */
  color: #4fd0ff;
  /* z-index: 0; */
  background-repeat: no-repeat;
  border-radius: 5px;
  /* Desired height */
  background-size: cover;
  /* Adjusts the background image to cover the entire container */
  background-position: right;
  /* Centers the background image */
  /* margin-bottom: 15px; */
}

.vault-wall {
  /* padding-top: 15px; */
  background-size: fill;
  /* Adjusts the size of the background image */
  background-position: center;
  /* float: right;s */
  background: transparent linear-gradient(113deg, #1F7073 0%, #083851 24%, #1E102F 63%, #1A0F30 82%, #30213D 100%) 0% 0% no-repeat padding-box;
  /* background-image: linear-gradient(to right,black, #4fd0ff); */
  color: #4fd0ff;
  border-radius: 5px;
  z-index: 2;
  background-repeat: no-repeat;

  /* Desired height */
  background-size: cover;
  /* Adjusts the background image to cover the entire container */
  background-position: right;

}

.featureHeading h1 {
  width: 100%;
}

@media (min-width:1280px) {


  .featureHeading h1:before,
  .featureHeading h1:after {
    content: "";
    position: absolute;
    top: 50%;
    border-bottom: 2px solid black;
    width: 20%;
  }

  .featureHeading h1:before {
    right: 5%;
    /* right: 50%; */
    /* margin-right: -15px; */
  }

  .featureHeading h1:after {
    /* left: 100%; */
    left: 5%;
    /* margin-left: -15px; */
    /* padding: 0 10px; */
  }
}

@media screen and (min-width:650px) and (max-width:1280px) {

  .featureHeading h1:before,
  .featureHeading h1:after {
    content: "";
    position: absolute;
    top: 50%;
    border-bottom: 2px solid black;
    width: 12%;
    margin: auto;
  }

  .featureHeading h1:before {
    right: 5%;

  }

  .featureHeading h1:after {
    /* left: 100%; */
    left: 5%;

  }

}

@media(max-width:650px) {

  .matisoft h1:before,
  .matisoft h1:after {
    display: none;
  }
}

@media (max-width:650px) {
  .featureHeading h1:after,
  .featureHeading h1::before,

  .useCase-underline::after,
  .useCase-underline::before,
  .award-underline h1::after,
  .award-underline h1::before {
    display: none;
    width: 0%;

  }
}

.grid-item {
  height: 25vh;

}

.usbVault {
  position: relative;
  width: 65%;

  border-radius: 3px;
}

.usbVault::before {
  content: '';

  /*   Adjust the height of the border */
  background: #4fd0ff;


  background-color: #F7C749;
  position: absolute;
  left: 50%;
  right: 0%;
  top: -5rem;
  z-index: -1;

}

.usbVault::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 17px;
  /*   Adjust the height of the border */
  background: #4fd0ff;
  content: "";
  width: 50%;
  height: 100%;
  left: 0;
  top: 10%;
  /* bottom: 10%; */
  background-color: #F7C749;
}

figure img {

  width: 23rem;
  height: 20rem;
  object-position: center;
  object-fit: contain;
  padding: 4px 0;
  border-radius: 5px;
  /* border: 10px solid #4fd0ff ; */
}

@media (max-width:650px) {
  figure img {
    display: none;
  }
}

figure {
  position: relative;
  /* border: 7px solid  #4fd0ff ; */
  border-radius: 5px;
  /* background: linear-gradient(to-r); */
}

.dlp-usb {
  position: relative;
}

.cookies-div {
  width: 100%;
  float: left;
  background-color: rgba(13, 19, 33, 0.8);
  color: white;
  position: fixed !important;
  z-index: 400 !important;
  bottom: 0;
  padding: 15px;
  text-align: center;
}

/* .cookies-content{} */
.cookies-content p {
  text-align: left;
  color: #C9EDFA;
  text-shadow: 0px 3px 6px #44ADE2;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 100 !important;
}

.cookies-content p a {
  color: #C9EDFA;
  text-decoration: underline;
}

/* .cookies-btn{} */
.cookies-btn .btn {
  text-transform: capitalize;
  background-color: #63c7f2;
  color: #0d1321;
}

.cookies-btn .btn-primary:hover {
  color: #63c7f2;
  background-color: transparent;
  border-color: #63c7f2;
}

.cookies-btn .btn-primary.active,
.cookies-btn .btn-primary:active,
.open>.dropdown-toggle.btn-primary {
  color: #63c7f2;
  background-color: transparent;
  border-color: #4D9BBE;
}

.cookies-btn .btn-primary.focus,
.cookies-btn .btn-primary:focus {
  color: #63c7f2;
  background-color: transparent;
  border-color: #4D9BBE;
}

.img-style1 {
  /* border: 10px solid #4fd0ff; */
  /* filter: hue-rotate(345deg); */
  padding-bottom: 15px;
  width: 260px;
  object-fit: contain;
}

.img-style1:nth-child(1) {
  position: absolute;
  top: 31%;
  right: 90%;
  width: 100px;
  padding-right: 5px;
  padding-top: 15px;
  /* transform: rotate(180deg); */
  /* filter: grayscale(100%); */

}

.img-style2 {}

/* .img-style1:nth-child(2){
  width: 120px;
  position: absolute;
  top:12%;right:86%;
} */
.slick-prev {
  color: red;
  /* color:#2dc6fd */

  /* Change this to the desired color */
}

/* Right arrow */
.slick-next {
  color: blue;
  /* Change this to the desired color */
}

.box .case-highlight {
  font-size: 16px;
  font-weight: 600;
  /* color: #2dc6fd; */
}

.contactBtn {

  border: 1px solid #1a1a22;
  margin-top: 15px;
  padding: 2px 10px;
  font-size: 15px;
  margin-left: 15px;
  display: inline-block;
  /* padding: 7px 20px; */
  margin-bottom: 0;

  font-weight: 400;
  border-radius: 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0px 2px 9px #57EAF580;
  color: #64c6f3 !important;
  color: #0D1523;
  background: linear-gradient(to bottom right, #0D1523, rgb(2, 19, 11), #0D1523);
  border-color: #4D9BBE;
}

.contactBtn:hover {
  background: linear-gradient(to right, #165877, #4D9BBE);
  background-color: #3b96b8;
}

.contactBtn:hover a {
  color: black;
  background-color: transparent;
}
.button1{
background-color: #4fd0ff;
color: #1a1a22;
border-radius: 1px;
border: 1px solid #4fd0ff;
box-shadow: 0 0 7px #4D9BBE;
padding: 5px 0 5px 0;

}
.button1:hover{
  background-color: #1a1a22;
color:#4fd0ff;
}


.navIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.triangle {
  color: red;
  font-size: medium;
}

.triangle {
  /* padding-right: 1px; */
}

.container {
  /* max-width: 1140px; */
  /* padding: 0px 15px; */
  width: 100%;

}

/* .container{width:100%} */

@media (max-width:640px) {
  .container {
    width: inherit;

    padding-left: 10px;
    padding-right: 25px;
    padding-left: 25px;

  }
}

@media (min-width:640px) {
  .container {
    max-width: 620px
  }
}

@media (min-width:768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width:1024px) {
  .container {
    max-width: 940px
  }
}

@media (min-width:1280px) {
  .container {
    max-width: 1140px
  }
}

@media (min-width:1536px) {
  .container {
    max-width: 1140px
  }
}


.font-heroHeading {
  font-family: 'MICROGME', sans-serif;
  /* text-align: center; */
  text-transform: uppercase;

  /* color: #4fd0ff; */
  letter-spacing: 6px;
  /* font-weight:500; */
  margin-bottom: 20px;

  margin-top: 0px;
  /* letter-spacing: 8px; */
  /* margin-bottom: 0px; */
  line-height: 45px;
  width: 80%;
  font-weight: 500;
  /* text-shadow: 0px 3px 6px #1a1a22; */
  font-size: 32px;
  text-transform: uppercase;
  font-family: 'microgme', sans-serif;

  letter-spacing: 5px;
  margin-bottom: 0px;
  line-height: 45px;
  /* text-align: justify; */
}

@media screen and (min-width:0px) and (max-width:649px) {

  .featureHeading h1::after,
  .featureHeading h1::before,
  .matisoft h1::after,
  .matisoft h1::before,
  .useCase-underline::after,
  .useCase-underline::before,
  .award-underline h1::after,
  .award-line h1::before {
    display: none !important;

  }
}

.footer {
  min-height: 35vh;
  height: auto;
  background: linear-gradient(180deg, #4fd0ff 0%, #1a1a22 100%) !important;
}

button.slick-arrow.slick-next {
  /* background-color: #F7C749; */
  color: red !important;
}

button.slick-arrow.slick-prev::after {
  /* background-color: aqua; */
  color: #9c27b0;
}

.your-carousel .slick-prev,
.your-carousel .slick-next {
  color: red;
}

/* CustomCarousel.css */
.custom-carousel-container {
  position: relative;
}

.carousel-slide {
  position: relative;
}

.custom-arrow-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-arrow {
  /* Style your custom arrows here */
  /* Example styles */
  width: 40px;
  height: 40px;
  font-size: 35px;
  color: gainsboro;
  /* background-color:red; */
  opacity: 0.7;
  cursor: pointer;
}

@media (max-width:640px) {
  .custom-arrow {
    font-size: 20px;
  }
}

.custom-prev {
  /* Style for previous arrow */
  left: 20px;
  /* Adjust position as needed */
}
.custom-next {
  /* Style for next arrow */
  right: 20px;
  /* Adjust position as needed */
}

.carousel-slide {
  position: relative;
}

.slide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  /* Ensure the content appears above the background */
  color: white;
  /* Example: set the text color */
}


.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  /* Ensure the arrows appear above the content */
  /* Add your arrow styles */
}
@media(max-width:640px) {
  .custom-arrow {
    /* display: none; */
  }
}

@media(min-width:740px) {
  .irfirst {
    text-align: justify !important;
  }
}
/* custom.css */
.text-shadow {
  @apply shadow-none; /* Remove the default shadow */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
/* toastStyles.css */
.toast-center {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translatex(-50%, -50%);
  z-index: 9999; /* Make sure it's on top */
}

/* toastStyles.css */
.toast-custom {
  font-size: 1.6em!important ;/* Adjust this size as needed */
  /* You can add other styles here, such as color, background, etc. */
}
.Toastify__toast {
  font-size: 18px; /* Adjust the size as needed */
  color: rgb(66, 65, 65);
}
@media screen and (max-width: 767px) {
  .toast-center {
    display: none !important; /* Hide the toast container */
  }
}
/* Streaming Text Line */
.streaming-text {
  display: flex;
  align-items: center;
  justify-content: center; /* Remove !important */
  margin: 0 auto; /* Center horizontally */
  background-color: #0d1321; /* Dark blue background */
  color: #4fd0ff; /* Cyan text */
  padding: 5px 10px; /* Padding for better spacing */
  font-size: 1em; /* Base font size */
  border: 1px solid #4fd0ff; /* Cyan border */
  text-align: center; /* Remove !important */
  width: 100%;
  position: relative; /* Needed for absolute positioning of child elements */
}
@media screen and (max-width: 767px) {
  .streaming-text {
font-size: 0.8em;
  }
}
.btnForLightbg {
  cursor: pointer;
  font-size: "1.2em";
  background-color: #4fd0ff; /* Light blue background */
  color: #1a1a22; /* Dark text color */
  font-weight: "600";
  border: none; /* Remove default border */
  padding: 8px 8px; /* Add padding for better appearance */
  box-shadow: 0 4px 6px rgba(79, 208, 255, 0.4), 0 2px 4px rgba(26, 26, 34, 0.3); /* Shadow around the button */
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}
.dpdp-hero{
  /* background-image:url(".././public/images/IMG-20250208-WA0004.jpg"); */
    background-image:url(".././public/images/dpdp.jpg"); 

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .dpdp-hero{
    background-position: left;
  }
}

.btnForLightbg:hover {
  background-color: #1a1a22; /* Dark background on hover */
  color: #4fd0ff; /* Light text color on hover */
  box-shadow: 0 4px 6px rgba(26, 26, 34, 0.4), 0 2px 4px rgba(79, 208, 255, 0.3); /* Shadow with reversed colors on hover */
}
input[type="checkbox"]:disabled {
  -webkit-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(14, 13, 13, 0.5); /* Transparent border */
  background-color: transparent; /* Transparent background */
  display: inline-block;
  position: relative;
  border-radius: 3px;
  cursor: not-allowed;
  opacity: 0.6;
  filter: brightness(85%);
}

input[type="checkbox"]:disabled:checked {
  background-color: darkgray; /* Darker background inside */
  border-color: rgb(31, 30, 30);
}

/* Custom checkmark styling for disabled and checked */
input[type="checkbox"]:disabled:checked::before {
  content: "✔"; /* Unicode checkmark */
  font-size: 14px;
  font-weight: bold;
  color: black; /* Visible checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
input[type="radio"]:disabled {
  -webkit-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(14, 13, 13, 0.5); /* Transparent border */
  background-color: transparent; /* Transparent background */
  display: inline-block;
  position: relative;
  border-radius: 50%; /* Circular shape for radio buttons */
  cursor: not-allowed;
  opacity: 0.6;
  filter: brightness(85%);
}

input[type="radio"]:disabled:checked {
  background-color: darkgray; /* Darker background inside */
  border-color: rgb(31, 30, 30);
}

/* Custom indicator styling for disabled and checked */
input[type="radio"]:disabled:checked::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: black; /* Visible indicator */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slick-prev:before,
.slick-next:before {
  color: rgb(26, 162, 216) !important; /* Change to desired color */
  font-size: 24px; /* Optional: Adjust size */
}
/* Apply only to elements with the 'thin-scrollbar' class */
.thin-scrollbar {
  overflow: auto; /* Ensures scrolling is enabled */
}

/* Thin scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.thin-scrollbar::-webkit-scrollbar {
  width: 6px; /* Thin width for vertical scrollbar */
  height: 6px; /* Thin height for horizontal scrollbar */
}

.thin-scrollbar::-webkit-scrollbar-track {
  background: #88898a; /* Light background for scrollbar track */
  border-radius: 10px;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #248aaf; /* Scrollbar thumb color */
  border-radius: 10px;
}

.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #36b8e6; /* Slightly darker color on hover */
}

/* Thin scrollbar for Firefox */
.thin-scrollbar {
  scrollbar-width: thin; /* Makes scrollbar thin */
  scrollbar-color: #4fd0ff #88898a; /* Thumb color | Track color */
}

