.button-new {
    cursor: pointer;
    background-color: #4fd0ff;
    padding: 1vh 1vw;
    color: black;
    border-radius: 3px;
    /* box-shadow: 0 3px 1px 3px red; */
    display: block;
    margin: 20px auto;
    
    /* color: white; */
 
    border: none;
    /* border-radius: 5px; */
    /* font-size: 1em; */
}

.button-new:hover {
    background-color: white;
    border: 1px solid #4fd0ff;
    color: #1a1a22;
}
.button1{
    cursor:pointer
}