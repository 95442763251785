
 
  .custom-select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    white-space: pre-wrap;
    word-wrap: break-word;
   
  }
  
  .custom-select option {
    white-space: pre-wrap; /* Ensures the text inside the options wraps */
    word-wrap: break-word; /* Ensures long words break properly */
  }
  .common-style {
    width: 24vw;
    margin: 0 auto;
  }
  
  .input-style {
    padding: 2vh 1vw;
    width: 100%;
    max-width: 100%;
    /* background-color:rgb(202, 225, 233); */
  }
  
  .break-words {
    word-wrap: break-word;
  }
  .no-border {
    border: none !important; /* Important to override any default styles */
    outline: none; /* Also remove the outline if needed */
    box-shadow: none; /* Remove any box-shadow that might be creating a border-like appearance */
  }
  .react-input-country-city__input {
    border: none !important;  /* Remove the border */
    outline: none !important; /* Remove the outline */
    box-shadow: none !important; /* Remove any box-shadow */
  }
  .input-field {
    display: block;
    width: 80%;
    margin: 10px auto;
    padding: 10px;
  }
  .signUp_container {
    background-image: url(../../public/images/background-6228127_1920.jpg);
    background-repeat: repeat;
    background-size: cover;
    min-height: 100vh; /* Ensure it covers at least the viewport height */
    width: 100%; /* Full width */
    font-size: 24px;

    position: relative; /* Required for positioning the pseudo-element */
}

@media screen and (min-width: 1450px) {
    .signUp_container {
        font-size: 30px;
    }
}

.signUp_container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    z-index: 1; /* Ensure the overlay is on top */
}

/* Ensure the background repeats correctly on smaller screens */
@media screen and (max-width: 767px) {
    .signUp_container {
        background-size: auto;
            height: 150vh;
        /* font-size: 19px; */
    }
}

  .radio-group {
    display: flex;

    align-items: flex-start;
    margin: 10px auto;
  }
  
  .radio-group label {
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-size: 0.8em;
  }
  
  .radio-input {
    width: 20px;  /* Adjust this to make the radio button larger */
    height: 20px; /* Adjust this to make the radio button larger */
    margin-right: 10px;  /* Space between radio button and label */

  }
/* signup.css */
.signUp_container .signUp-input input, .country_SignUp {
  width: 100%;
  font-size: 0.8em;
  padding: 1vh 1vw;
  border: 1px solid gray;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding doesn't affect total width */
  margin: 1vh 0;
  height: 40px; /* Explicitly set a height to match both elements */
}
/* .next-btn {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 1em;
} */
 .input-country{
  border: none;
  outline: none;
 }
 .countryContainer{
  font-size: 0.8em;
 }
 .thankYou_container{
font-size: 26px;
width: 100vw;
margin: 0 auto;
 }

 .thankYou_top{
  /* background-image: url(../../public/images/abstract-1780231_1280.png); */
  background-image: url(../../public/images/texture-976039_1920.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
 
 }
 /* .thankYou_top::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0; 
} */
.thanyou_steps >div{
  box-shadow: 0 0 5px 3px rgb(224, 224, 224);
}
.preSignUp{
height: 100vh;
width: 100%;
/* background-repeat: no-repeat;
background-position: right;
background-size: cover;
background-image: url(../../public/images/cyber-security-2296269_1920.jpg); */
font-size: 20px;
}
.custom-select:focus {
  border-color: #4fd0ff; /* Change this color to your preferred focus border color */
  outline: none; /* Remove the default outline */
  box-shadow: 0 0 0 1px #1e40af; /* Optional: add a subtle shadow to highlight the focus */
}
.completeSignUp{
  font-size: 22px;
}
@media screen and (min-width: 1450px) {
 .completeSignUp{
  font-size: 24px;
}
}
.login_Container{
  /* background-image: url('../../public/images/network-3484139_1920.jpg'); */
    background-image: url('../../public/images/camera-3376230_1920.jpg');

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width: 1450px) {
  .login_Container{
  font-size: 22px;
}
}
/* General dropdown styles */
.country_SignUp {
  color: black; /* Default color for the dropdown text */
}

/* Styling for all options */
.country_SignUp option {
  color: black; /* Default color for the options text */
}

/* Styling for the placeholder (default option) */
.country_SignUp option[value=""] {
  color: rgb(160, 156, 156); /* Placeholder color */
}
@media screen and (min-width:1400px) {
  .thankYou_container{
    font-size: 30px;
  }
  
}
.form-input {
  /* background-color: white; */
  border: 1px solid gray!important;
  border-radius: 4px;
  padding: 4px;
  font-size: 0.8em;
  width: 100%;
  box-sizing: border-box;
  height: 2em; /* Set a consistent height */
}

.form-input:focus {
  outline: none;
  border-color: #4fd0ff!important;
  /* box-shadow: 0 0 3px #4fd0ff; */
}

.form-inputCompleteSignUp {
  /* background-color: white;
  border: 1px solid lightgray; */
  /* border: 1px solid lightgray;  */
  border-radius: 4px;
  padding: 4px;
  font-size: 0.9em;
  width: 100%;
  box-sizing: border-box;
  height: 2.5em; /* Set a consistent height */
  background-color: white;
}

.form-inputCompleteSignUp:focus {
  outline: none;
  /* border-color: #4fd0ff;
  box-shadow: 0 0 3px #4fd0ff; */
  border-color: #4fd0ff;
}