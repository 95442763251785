.heroDlp{
    
    background-image: url('../../public/images/cybersecurity-5642004_1920.jpg');
    background-size: cover;
/* float: right; */
position: float;
    background-position:center;
    background-repeat: no-repeat;
    color: black;
    /* opacity: 0.5; */
    /* z-index: 10; */
}
@media(min-width:1500px){
  .heroDlp p{
    margin-top: 8%;
    font-size:40px;
    line-height: 70px; width: 68%;
  }
  .heroDlp .btn{
    margin-top: 20px;
    font-size: 18px;
    padding-left: 8px;
    padding-right:8px;
  }
}


.heroDlp1{
    
  background-image: url('../../public/images/DLP-hero.jpg');
  background-size: cover;
/* float: right; */
position: float;
  background-position:center;
  background-repeat: no-repeat;
  color: black;
  /* opacity: 0.5; */
  /* z-index: 10; */
}

@media(min-width:1500px){
.heroDlp1 p{
  margin-top: 8%;
  font-size:40px;
  line-height: 70px; width: 68%;
}
.heroDlp1 .btn{
  margin-top: 20px;
  font-size: 18px;
  padding-left: 8px;
  padding-right:8px;
}
}
.hero-background{
    /* background-color: #4fd0ff;
  opacity: 0.3;
  background-image: linear-gradient(0deg, #4fd0ff 50%, #1a1a22 50%);
  background-size: 40px 40px; */
  /* background-image:url('../../public/images/dlp-13.webp'); */
  position: float;
  background-size: cover; /* Adjusts the size of the background image */
    background-position:right;
  float: right;
  color: #4fd0ff;
  
  }
  .customerlogocontainer{
    max-height: 23vh;
    width: 95%;
    object-fit: scale-down
  }
  .hero-background::after {
    content: ''; /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
     z-index: -2;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 34, 0.4);
    /* background-color: rgba(79, 208, 255, 0.1); */
    /* Overlay color (blue with 50% opacity) */
  }
  .dlpHeroBtn{
    border:none;
    padding: 1vh 8px !important;
    background-color: #0d1321 !important;
    color: white;
    font-weight: 600;
    /* color: #24c5ff; */
    box-shadow: 0px 0px black !important;

    
    }
    @media(min-width:640px){
      .dlpHeroBtn{
         padding: 2px 8px !important;
      }
    }
    
    .dlpHeroBtn:hover{
      background-color: #4fd0ff !important;
      color: white;
    }
  .dlp-usb>h1{
padding-bottom: 0;
margin-bottom: 0;
  }
  .dlp-usb>div{
    max-width: 55%;
  }
  @media(max-width:650px){
    .dlp-usb>div{
      max-width: 100%;
      margin: auto;
    }
  }
  .dlp-usb figure{
    max-width: 35%;
  }
  .dlp-usb p{
    padding-top: 0;
    padding-bottom: 0px;
    margin-top: 0;
 
    font-size: 20px;
    max-width: 100%;
  }
 
.dlp-usb ol{
    padding-top: 0px;
}
.dlp-usb ol>li{
    max-width: 90%;
    margin: auto;
    /* row-gap: 4px; */
    padding-bottom: 15px;
    font-size: 15px;
    -webkit-line-clamp: 0px;
    letter-spacing: 3px;
    line-height:auto;
    /* padding-top: 5px; */
    padding-bottom: 3%;
    line-height: 1.4;
  
}
@media(min-width:1400px){
  .dlp-usb p{
    font-size: 22px;
  }
  .dlp-usb ol>li{
    font-size: 20px;
  }
}
.partner-box{
  border: 0px;
  color: white;
  position: relative;
  /* background: red; */
}
.partner-box>div{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.partner-box>div>img{
  width:1/2;
  height:fit-content;
  object-fit: contain;
}
@media (min-width:1400px){
  /* #awards{
    display: flex;
    justify-content: center;
    align-items: center;

  }
  #awards h1{
    margin-bottom: 3%;
  } */
 #awards .grid-container{

 }
  /* #awards{
    position: relative;
  }

  #awards .award-underline{
    position: absolute;
    top: 15%;
      left: 5%;
      right: 5%;
    
  }
  #awards .grid-container{
    position: absolute;
    row-gap: 20%;
    top: 30%;
    left: 15%;right: 15%;

  } */
  /* #customers{
    padding-top: 0%;
    
    display: flex;

    justify-content: center;
    align-items: center;
  }
  #customers>div{
    padding-top: 0%;
  } */
}
@media(min-width:1400px){

  
}
@media(min-width:1400px){
#dlpFeatures,#useCases,#awards,#partners,#customers
{
  display: flex;
  align-items: center;
  justify-content: center;
}
#useCases>div{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}}
.overlay-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* This adds a semi-transparent black overlay */
  z-index: 1;
}
.overlay-layer1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* This adds a semi-transparent black overlay */
  z-index: 1;
}
.text-layer {
  position: relative;
  z-index: 2;
}
.dlp-icon-arrow{
font-size: 35px;
}