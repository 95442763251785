.heroir-background {
    /* background-image: url('../../public/images/hacker-3342696_1920.jpg'); */

    background-image: url('../../public/images/background-image-1273097_1280.webp');
    background-size: cover;
    /* float: right; */
    position: float;
    background-position: right;
    background-repeat: no-repeat;
    color: aqua;

    /* opacity: 0.5; */
    /* z-index: 10; */
}

.heroir-background>div {
    width: 87%;
}

@media(min-width:1400px) {
    .irfirst>h1 {
        font-size: 45px;
    }
}

.font-heroHeading {
    font-family: 'MICROGME', sans-serif;
    /* text-align: center; */
    text-transform: uppercase;

    /* color: #4fd0ff; */
    letter-spacing: 6px;
    /* font-weight:500; */
    margin-bottom: 20px;
    /* text-align: center; */
    /* font-size: 30px; */
    /* text-transform: uppercase; */
    /* font-family: 'microgme', sans-serif; */
    /* color: #4FD0FF; */
    margin-top: 0px;
    /* letter-spacing: 8px; */
    /* margin-bottom: 0px; */
    line-height: 45px;
    width: 80%;
    font-weight: 500;
    /* text-shadow: 0px 3px 6px #1a1a22; */
    font-size: 32px;
    text-transform: uppercase;
    font-family: 'microgme', sans-serif;

    letter-spacing: 5px;
    margin-bottom: 0px;
    line-height: 45px;
    /* text-align: justify; */
    /* text-align: left; */
}

@media (max-width: 1024px) {
    .font-heroHeading {
        font-size: 26px;
        letter-spacing: 4px;
        margin-bottom: 10px;
        line-height: 36px;
        width: 80%;
    }
}

@media (max-width: 479px) {
    .font-heroHeading {
        font-size: 18px;
        letter-spacing: 4px;
        margin-bottom: 0px;
        line-height: 24px;
        width: 100%;
        /* text-align: left; */
    }
}

.heroir-background::after {
    content: '';
    /* Required for pseudo-elements */
    /* position: absolute;
    top: 0;
    left: 0;
     z-index: 0;
    width: 100%;
    height: 100%; 
    background-color: rgba(26, 26, 34, 0.6); */
}

.featureBtn {
    padding: 7px 0;
    position: relative;
}

.featureBtn::after {
    /* content:"";
    position: absolute;
    bottom: 0; */

}

.featureBtn:hover {

    transition: 0.5s ease;
    /* transform: translateY(3px);  */
    border-bottom: 2px solid #47c8fc;
}

.newclass {
    /* color: pink; */
}

.incidentsBox {
    border: 1px solid gray;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    width: 100%;

    /* padding: 0px 8px; */
    height: 45vh;
    margin: auto;
}

.incidentsBox:hover {
    position: relative;
    transition: transform 0.2s ease;
    transform: translateY(-10px);
}

@media(min-width:640px) {
    .incidentsBox h3 {
        min-width: 90%;
        position: absolute;
        top: 58%;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);

    }
}


.caseStudy {
    /* border: 1px solid #1a1a22; */
    transition: 0.9s ease;
    animation: rotateSlide 1s forwards;


}

/* @keyframes rotateSlide {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(180deg); } 
  } */
.caseStudy:not(:hover) {
    transform: rotate(0deg);
    /* Return to the original position when not hovered */
}

.caseStudyIr-container h4 {
    display: none;
}

.caseStudyIr-container h3 {

    position: absolute;
    top: 70%;
}

.caseStudyIr-container::after {
    content: '';
    /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 25;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 34, 0.5);
    /* display: none; */
    padding: 7px 5px;
    padding-bottom: 10px;
    /* background-color: lightblue; */

}

.caseStudy:hover .caseStudyIr-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 25;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 34, 0.7);
    padding: 7px 5px;
    padding-bottom: 10px;
}

.caseStudy:hover .caseStudyIr-container h3 {
    transition: 0.2s ease;
    position: absolute;
    top: 9%;
    z-index: 26;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 4px;
    color: #fff;
}

.caseStudy:hover .caseStudyIr-container h4 {
    display: block;
    transition: 0.2s ease;
    position: absolute;
    top: 55%;
    z-index: 26;
    font-size: 15px;
    font-weight: 500;
    text-align: justify;
    padding-bottom: 13px;
    color: #fff;
}

.caseStudyIr-container {
    background-position: center;
    background-repeat: no-repeat;

}

.caseStudyIr-container:hover {
    transition: 0.3s ease;
    background-color: white;
    color: black;

    border-radius: 2px solid #1a1a22;
}

.caseStudyIr-container h2 {}

.caseStudyIr-container h3 {}

.caseStudyIr-container p {}

.caseStuxnet {
    background-image: url(../../public/images/blog-bg2.png);
    background-size: cover;
    height: 54vh;
    object-fit: contain;
}

.caseStuxnet::after {
    content: '';
    /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 34, 0.4);
}

.caseShadowPad {
    background-image: url(../../public/images/shadowpad.jpg);
    background-size: cover;
    height: 54vh;
    object-fit: contain;
}

.caseShadowPad::after {
    content: '';
    /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 34, 0.4);
}

.caseRansomware {
    background-image: url(../../public/images/djvu.jpg);
    background-size: cover;
    height: 54vh;
    /* width: 10vw; */
    object-fit: contain;
    position: relative;
    z-index: 20;
}

.caseRansomware::after {
    content: '';
    /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 34, 0.4);
}

.caseStudyIr-container>h2 {
    z-index: 10;
}

.caseStudyIr-container>h3 {
    z-index: 10;
}

.caseWMI {
    background-image: url(../../public/images/wmi.jpg);
    background-size: cover;
    height: 54vh;
    /* width: 10vw; */
    position: relative;
    object-fit: contain;
    z-index: 0;

}

.caseWMI::after {
    content: '';
    /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 34, 0.4);
}

.casePlugx {
    background-image: url(../../public/images/plugx.jpg);
    background-size: cover;
    height: 54vh;
    /* width: 10vw; */
    object-fit: contain;
}

.casePlugx::after {
    content: '';
    /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 34, 0.4);
}

.historyIr {
    background: url('../../public/images/model-3211627_1920.jpg');
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
}

/* .historyIr::after{
        content: ''; 
        position: absolute;
        top: 0;
        left: 0;
         z-index: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(102, 179, 226, 0.4);
       } */
.historyIr-points li {
    height: 11vh;
    text-align: justify;
    margin: 0px auto;

}

.historyBoxIR {
    /* border: 1px solid #fff;*/
    border-radius: 10px;
    width: 90%;
    border: 1px solid white;
    /* padding: 0px 8px; */
    min-height: 47vh;
    max-height: auto;
    height: fit-content;
    position: relative;
    /* background-color: chartreuse; */
    z-index: 40;
    overflow-y: hidden;
}

.historyBoxIR h3 {
    padding-top: 1vh;
    padding-bottom: 5px;
}

.font-aboveLow {
    margin-top: 0px;
    /* letter-spacing: 8px; */
    /* margin-bottom: 0px; */

    width: 100%;
    font-weight: 400;
    /* text-shadow: 0px 3px 6px #1a1a22; */
    font-size: 14px;

    font-family: 'microgme', sans-serif;
    /* color: white; */
    margin-top: 0px;
    /* letter-spacing: 1px; */
    margin-bottom: 0px;
    line-height: 15px;
    width: 100%;
}

@media screen and (max-width:650px) and(min-width:420px) {
    .font-aboveLow {
        font-size: 4px !important;
        line-height: 8px;
    }
}

.caseSamsam {
    background-image: url(../../public/images/samsam.jpg);
    background-size: cover;
    height: 54vh;
    /* width: 10vw; */
    object-fit: contain;
}

.caseSamsam::after {
    content: '';
    /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 34, 0.4);
}

.caseCobaltKitty {
    background-image: url(../../public/images/kitty.jpg);
    background-size: cover;
    height: 54vh;
    /* width: 10vw; */
    object-fit: fill;
}

.caseCobaltKitty::after {
    content: '';
    /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 34, 0.4);
}

.caseAPT {
    background-image: url(../../public/images/apt29.png);
    background-size: cover;
    height: 54vh;
    background-position: center;
    /* width: 10vw; */
    object-fit: contain;
}

.caseAPT::after {
    content: '';
    /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 34, 0.4);
}

.slick-prev,
.slick-next {
    color: gray;
}


@media screen and (min-widh:480px) and (max-width:620px) {
    .font-aboveLow {
        font-size: 6px;
    }
}

#featuresIR {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* #irtheory{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
    } */
@media(min-width:1024px) {

    #historyIR,
    #casestudy,
    #irtheory {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media(min-width:1400px) {
    .irfirst {
        font-size: 45px !important;
    }

    .irfirst p {
        font-size: 26px !important;
        line-height: 38px !important;
        letter-spacing: 4px;
    }

    .irfirstSub {
        line-height: 25px !important;

    }

    #irusp>h1 {
        font-size: 45px;
    }

    #irusb p {
        line-height: 38px !important;
    }

}